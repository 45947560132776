import * as React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import PopupMessage from "./Message/PopupMessage";
import "./PopupWrapper.css";

class PopupWrapper extends React.Component<any, any> {

    private readonly defaultPopupMessage: { [key: string]: any } = {
        title: "Information",
        message: "",
        confirmButtonName: "Okay",
        cancelButtonName: "Abbrechen",
        buttonType: "primary",
        headingType: "primary"
    };

    constructor(props: any) {
        super(props);
        this.state = {
            popupMessage: this.defaultPopupMessage,
            modal: false
        };

        this.onCancel = this.onCancel.bind(this);
        this.onConfirm = this.onConfirm.bind(this);

    }

    public toggle() {
        this.setState((prevState: any) => ({
            modal: !prevState.modal,
            ...this.defaultPopupMessage
        }));
    }

    public onConfirm() {
        this.toggle();
        if (this.state.popupMessage.onConfirm) {
            this.state.popupMessage.onConfirm();
        }
    }

    public onCancel() {
        this.toggle();
        if (this.state.popupMessage.onCancel) {
            this.state.popupMessage.onCancel();
        }
    }

    public render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.onCancel} className={this.props.className}>
                    <ModalHeader
                        className={"PopupWrapper-Heading " + this.state.popupMessage.headingType}
                        toggle={this.onCancel}
                    >
                        {this.state.popupMessage.title}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.popupMessage.message}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color={this.state.popupMessage.buttonType}
                            onClick={this.onConfirm}
                        >
                            {this.state.popupMessage.confirmButtonName}
                        </Button>
                        {" "}
                        <Button
                            hidden={!this.state.popupMessage.onCancel}
                            color="secondary"
                            onClick={this.onCancel}
                        >
                            {this.state.popupMessage.cancelButtonName}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    public newPopup(popupMessage: PopupMessage) {
        this.setMissingFieldsToDefault(popupMessage);
        this.setState({
            popupMessage,
            modal: true
        });
    }

    private setMissingFieldsToDefault(popupMessage: any): void {
        for (const key of Object.keys(this.defaultPopupMessage)) {
            if (!popupMessage[key]) {
                popupMessage[key] = this.defaultPopupMessage[key];
            }
        }
    }
}

export default PopupWrapper;
