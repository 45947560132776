import Message from "./Message";
import MessageCreator from "./MessageCreator";

export default class InvalidCsrfProtectionIdMessageCreator implements MessageCreator<Message> {
    protected static readonly type: string = "invalid_csrf_protection_id";
    private message: Message = {};

    public constructor() {
        const message: Message = {};
        message.type = InvalidCsrfProtectionIdMessageCreator.type;
        this.message = message;
    }

    public getMessage(): Message {
        return this.message;
    }
}
