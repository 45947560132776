import {MessageHandler} from "./MessageHandler";
import RedirectToApplicationMessage from "../Message/RedirectToApplicationMessage";

export default class RedirectToApplicationMessageHandler implements MessageHandler<RedirectToApplicationMessage> {

    private readonly handleRedirectToApplication: (appName: string) => void;

    constructor(handleRedirectToApplication: (appName: string) => void) {
        this.handleRedirectToApplication = handleRedirectToApplication;
    }

    public handle(message: RedirectToApplicationMessage): void {
        this.handleRedirectToApplication(message.appName);
    }
}
