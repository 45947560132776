import {MessageHandler} from "./MessageHandler";
import OutgoingMessageHandler from "./OutgoingMessageHandler";
import ComponentReferenceStore from "../ComponentReferenceStore";
import OutgoingMessageCreator from "../Message/OutgoingMessageCreator";
import FetchStateMessage from "../Message/FetchStateMessage";

export default class FetchStateMessageHandler implements MessageHandler<FetchStateMessage> {

    private readonly outgoingMessageHandler: OutgoingMessageHandler;

    constructor(
        outgoingMessageHandler: OutgoingMessageHandler,
    ) {
        this.outgoingMessageHandler = outgoingMessageHandler;

    }

    public handle(message: FetchStateMessage): void {
        const fetchedData: { [key: string]: any } = {};

        for (const moduleInstanceId of message.applicationModuleInstanceIdsToFetchStateFrom) {
            fetchedData[moduleInstanceId] = ComponentReferenceStore.getComponentState(moduleInstanceId);
        }

        const outgoingMessageCreator = new OutgoingMessageCreator(message.screenIdToTriggerFetchedEventFrom, {
            guid: message.applicationModuleInstanceIdToTriggerFetchedEventFrom,
            eventType: "sendRequestedState",
            data: fetchedData,
        });

        this.outgoingMessageHandler.handle(outgoingMessageCreator.getMessage());
    }

}
