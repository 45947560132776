import {MessageHandler} from "./MessageHandler";
import RedirectToUrlMessage from "../Message/RedirectToUrlMessage";

export default class RedirectToUrlMessageHandler implements MessageHandler<RedirectToUrlMessage> {
    public handle(message: RedirectToUrlMessage): void {
        if (message.url) {
            window.location.replace(message.url);
        }
    }
}
