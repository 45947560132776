import {MessageHandler} from "./MessageHandler";
import OpenEmailTemplateMessage from "../Message/OpenEmailTemplateMessage";

export default class OpenEmailTemplateMessageHandler implements MessageHandler<OpenEmailTemplateMessage> {

    public handle(message: OpenEmailTemplateMessage): void {
        OpenEmailTemplateMessageHandler.handleSendMail(message.mailToString);
    }

    private static unescapeHTML(escapedHtmlString: string): string {
        const temp = document.createElement("textarea");
        temp.innerHTML = escapedHtmlString;
        return temp.value;
    }

    private static handleSendMail(mailToLink: string) {
        window.location.href = OpenEmailTemplateMessageHandler.unescapeHTML(mailToLink);
    }

}
