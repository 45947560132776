import * as React from "react";
import VayuModule from "../VayuModule";
import "./Button.css";

class Button extends VayuModule {
    public render() {
        return this.renderButton();
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData);
    }

    protected renderButton() {
        const classBtn = "Button-container btn btn-" +
            this.state.type +
            (this.state.invisible ? " not-visible" : "");
        return (
            <button
                type="button"
                className={classBtn}
                onClick={this.onClick}
                disabled={this.getDisabled()}
            >
                {this.state.text}
            </button>
        );
    }

    protected getDisabled(): boolean {
        return !!this.state.disabled;
    }

    protected getDefaultState(): object {
        return {
            type: "secondary",
            text: "",
        };
    }

    protected onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.onClick !== undefined) {
            this.props.onClick(this, e);
        } else {
            this.sendEventToBackend("click");
        }
    };
}

export default Button;
