import * as React from "react";
import VayuModule from "../VayuModule";
import "./Text.css";

class Text extends VayuModule {
  public render() {
    let classStyling: string = "";

    if (this.state.isItalic) {
      classStyling += " Text-italic";
    }
    if (this.state.isBold) {
      classStyling += " Text-bold";
    }

    if (this.state.isUnderline) {
      classStyling += " Text-underline";
    }

    if (this.state.isStrikeThrough) {
      classStyling += " Text-strikethrough";
    }

    if (this.state.isRedo) {
      classStyling += " Text-redo";
    }

    return <div className={classStyling}>{this.state.label}</div>;
  }

  public onContentUpdate(newData: any): void {
    this.setState(newData);
  }

  protected getDefaultState(): object {
    return {
      label: "Text",
      isItalic: false,
      isBold: false,
      isUnderline: false,
      isStrikethrough: false,
      isRedo: false
    };
  }

  public getContent(): any {
    return this.state;
  }
}

export default Text;
