// This must be the first line in src/index.js
import "react-app-polyfill/ie11";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Application from "./Application";

import {BrowserRouter} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// comment out to enable progressive web app support through the workbox libs https://github.com/GoogleChrome/workbox
// import registerServiceWorker from './registerServiceWorker';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.

ReactDOM.render(
    (
        <BrowserRouter>
            <Application/>
        </BrowserRouter>
    ),
    document.getElementById("root") as HTMLElement,
);

// comment out to register/ enable workbox
// registerServiceWorker();
