import * as React from "react";
import VayuModule from "../VayuModule";
import "./InputField.css";

class InputField extends VayuModule {
    /*
          event override happens in onChange function
       */

    public render() {
        const classes = "InputField-container";
        return <div className={classes}>{this.renderInputField()}</div>;
    }

    public getContent(): any {
        return this.state.value;
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData);
    }

    protected getDefaultState(): object {
        return {
            propagateEvents: false,
            type: "text",
            value: "",
            placeholder: "placeholder",
        };
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        this.setState(
            {
                value: target.value,
            },
            () => {
                if (this.props.onChange !== undefined) {
                    e.persist();
                    this.props.onChange(this, e);
                } else {
                    if (this.state.propergateEvents) {
                        this.sendEventToBackend("change", {value: target.value});
                    }
                }
            },
        );
    };

    private renderInputField() {
        const classIF = "form-control";
        return (
            <input
                type={this.state.type}
                disabled={(!!this.state.disabled)}
                className={classIF}
                value={this.state.value}
                placeholder={this.state.placeholder}
                onChange={this.onChange}
            />
        );
    }
}

export default InputField;
