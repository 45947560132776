import * as React from "react";
import VayuContainerModule from "../VayuContainerModule";
import "./Screen.css";

class Screen extends VayuContainerModule {

    private static currentlyDisplayedScreenGuid: string = "";

    public static setCurrentlyDisplayedScreenGuid(screenGuid: string) {
        Screen.currentlyDisplayedScreenGuid = screenGuid;
    }

    public static getCurrentlyDisplayedScreenGuid(): string {
        return Screen.currentlyDisplayedScreenGuid;
    }

    public render() {
        return this.renderContainer();
    }

    protected getDefaultState(): object {
        return {
            enableOnDocumentReady: false,
            backgroundColor: "#ffffff",
        };
    }

    protected renderContainer() {
        const screenStyle = {
            backgroundColor: this.state.backgroundColor,
        };
        return (
            <div className="screen-container vay-container" style={screenStyle}>
                <div className="vayu-container-node screen-node">
                    {this.renderChildren()}
                </div>
            </div>
        );
    }

    public componentDidMount(): void {
        if (this.state.enableOnDocumentReady && (this.screenGuid === Screen.currentlyDisplayedScreenGuid)) {
            //the frontend message handler depends on some logic that depends on react references which wont be ready at this point
            setTimeout(() => {
                this.sendEventToBackend("onDocumentReady", {});
            }, 100);
        }
    }
}

export default Screen;
