import ModuleEventMessage from "./ModuleEventMessage";
import OutgoingMessage from "./OutgoingMessage";
import MessageCreator from "./MessageCreator";

export default class OutgoingMessageCreator implements MessageCreator<OutgoingMessage> {

    protected static readonly type: string = "outgoing";
    private readonly message: OutgoingMessage;

    public constructor(screenId: string, message: ModuleEventMessage) {
        this.message = {
            type: OutgoingMessageCreator.type,
            content: {
                csrfProtectionId: OutgoingMessageCreator.getCsrfProtectionId(),
                sessionId: OutgoingMessageCreator.getSessionId(),
                screenId,
                message,
            }
        };
    }

    public getMessage(): OutgoingMessage {
        return this.message;
    }

    private static getSessionId(): string {
        const htmlTag: any = document.getElementById(
            "request_authentication_ids",
        );
        const sessionId: string | null = htmlTag.getAttribute(
            "current_session_id",
        );
        return sessionId === null ? "" : sessionId;
    }

    private static getCsrfProtectionId(): string {
        const htmlTag: any = document.getElementById(
            "request_authentication_ids",
        );
        const csrfProtectionId: string | null = htmlTag.getAttribute(
            "csrf_protection_id",
        );
        return csrfProtectionId === null ? "" : csrfProtectionId;
    }
}
