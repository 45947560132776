import * as React from "react";
import "./DynamicTiles.css";
import VayuSuperModule from "../VayuSuperModule";
import Tile from "./Tile";

class DynamicTiles extends VayuSuperModule {
    public render() {
        return this.renderContainer();
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData, () => {
            for (const tile of this.state.tiles) {
                const tileRef = this.getComponentReference(tile.guid);
                tile.initData.borderRadius = this.state.borderRadius;
                tileRef.onContentUpdate(tile.initData);
            }
        });
    }

    protected getDefaultState(): object {
        return {
            heading: "insert heading",
            description: "insert description",
            tileNumber: 3,
            borderRadius: "3px",
            tiles: [],
        };
    }

    protected renderContainer() {
        return (
            <div key="container" className="DynamicTiles">
                <div className="DynamicTiles-wrapper">
                    {[this.renderMeta(), this.renderTilesContainer()]}
                </div>
            </div>
        );
    }

    protected renderMeta() {
        return (
            <div key="meta" className="container">
                <div className="row">
                    {this.renderHeading()}
                    {this.renderDescription()}
                </div>
            </div>
        );
    }

    protected renderTilesContainer() {
        return (
            <div key="tiles" className="container">
                <div className="row">{this.renderTiles()}</div>
            </div>
        );
    }

    protected renderTiles(): JSX.Element[] {
        const list = [];
        const tiles = this.state.tiles;
        let i = 0;
        for (const tile of tiles) {
            list.push(this.renderTile(i, tile));
            i++;
        }
        return list;
    }

    protected renderTile(childIndex: number, tile: any) {
        let classStr: string = "col-md-";
        if (this.state.tileNumber === 4) {
            classStr += "3";
        } else if (this.state.tileNumber === 3) {
            classStr += "4";
        } else if (this.state.tileNumber === 2) {
            classStr += "6";
        } else if (this.state.tileNumber === 1) {
            classStr += "12";
        } else {
            classStr += "4";
        }

        const styleTiles: any = {
            borderRadius: this.state.borderRadius,
        }
        return (
            <div key={tile.guid} className={classStr} style={styleTiles}>
                <Tile
                    guid={tile.guid}
                    ref={this.addChildReference}
                    initData={tile.initData}
                    onClick={this.onTileClick}
                />
            </div>
        );
    }

    protected onTileClick = (tile: Tile, event: React.SyntheticEvent) => {
        this.sendEventToBackend("tileClick", {
            label: tile.props.initData.label,
            value: (tile.props.initData.value ? tile.props.initData.value : undefined),
        });
    };

    private renderHeading() {
        return (
            <div key="heading" className="col-md-12">
                <h1>{this.state.heading}</h1>
            </div>
        );
    }

    private renderDescription() {
        return (
            <div key="description" className="col-md-12">
                <h2>{this.state.description}</h2>
            </div>
        );
    }
}

export default DynamicTiles;
