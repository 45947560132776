import * as React from "react";
import LoadingScreenMessage from "./Message/LoadingScreenMessage";
import "./LoadingScreenWrapper.css";

export default class LoadingScreenWrapper extends React.Component<any, any> {

    private defaultState = {
        show: false,
    };
    private static readonly LOADING_SCREEN_TIMEOUT = 60000;
    private timeout!: NodeJS.Timeout;

    protected constructor(props: any) {
        super(props);
        this.state = {
            show: props.show || this.defaultState.show
        };
    }

    public handleLoadingScreenMessage(loadingScreenMessage: LoadingScreenMessage): void {
        this.setState({
            show: loadingScreenMessage.show,
        });

        if (loadingScreenMessage.show) {
            this.handleLoadingScreenTimeout();
        } else {
            clearTimeout(this.timeout);
        }
    }

    public render() {
        return (
            <div id="loader-wrapper" style={{display: this.state.show ? "block" : "none"}}>
                <div id="loader"/>
            </div>
        );
    }

    private handleLoadingScreenTimeout() {
        this.timeout = setTimeout(() => {
                this.setState({show: false});
            },
            LoadingScreenWrapper.LOADING_SCREEN_TIMEOUT
        );
    }
}
