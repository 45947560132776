import * as React from "react";
import VayuContainerModule from "../VayuContainerModule";
import "./ButtonBar.css";

class ButtonBar extends VayuContainerModule {
  public render() {
    return <div className="ButtonBar-container">{this.renderContainer()}</div>;
  }

  protected getDefaultState(): object {
    return {
      align: "align-left"
    };
  }

  protected renderContainer() {
    const classes = "ButtonBar-wrapper " + this.state.align;
    return <div className={classes}>{this.renderButtons()}</div>;
  }

  protected renderButtons(): JSX.Element[] {
    const list = [];
    const buttons: JSX.Element[] = this.renderChildren();
    for (let i = 0; i < buttons.length; i++) {
      list.push(this.renderButton(i, buttons[i]));
    }
    return list;
  }

  protected renderButton(childIndex: number, button: JSX.Element) {
    return <div key={this.componentGuid + childIndex}>{button}</div>;
  }
}

export default ButtonBar;
