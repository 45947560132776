import * as React from "react";
import ComponentRouter from "./ComponentRouter";
import MessageBroker from "./MessageBroker";
import WebsocketClient from "./WebsocketClient";
import "./Application.css";

import {withRouter} from "react-router-dom";
import VayuModule from "./VayuModule";
import IncomingMessage from "./Message/IncomingMessage";
import ComponentReferenceStore from "./ComponentReferenceStore";
import RoutingTableManager from "./RoutingTableManager";
// @ts-ignore
import Screen from "./Modules/Screen";
import Message from "./Message/Message";
import Ajax from "./Ajax";
import PopupMessageCreator from "./Message/PopupMessageCreator";
import GlobalWrapperComponentsManager from "./GlobalWrapperComponentsManager";
import PopupMessage from "./Message/PopupMessage";

class Application extends React.Component<any, any> {
    private websocketClient: WebsocketClient;
    private messageBroker: MessageBroker;
    private globalReactComponentsManagerRef!: GlobalWrapperComponentsManager;

    private setCurrentlyDisplayedScreenGuid() {
        Screen.setCurrentlyDisplayedScreenGuid(RoutingTableManager.getScreenGuidOfCurrentScreen());
    }

    constructor(props: any) {
        super(props);
        this.websocketClient = new WebsocketClient(
            this.websocketMessageCallBack,
            this.websocketHandleOnConnectError,
        );
        this.messageBroker = new MessageBroker(
            this.websocketClient,
            this.changeScreen
        );
        VayuModule.setEventHandler(
            this.messageBrokerApplicationEventHandlerCallBack,
        );

        this.setCurrentlyDisplayedScreenGuid();

        // tslint:disable-next-line:max-line-length
        Ajax.setShowPopupHandler((message: PopupMessage) => this.globalReactComponentsManagerRef.showPopupMessage(message));
    }

    public render() {
        ComponentReferenceStore.clear(); // to clear out old references,
        // otherwise use oop and set the ComponentReferenceStore as a static dependency for VayuModule
        return (
            <div>
                <ComponentRouter/>
                <GlobalWrapperComponentsManager
                    ref={this.setGlobalReactComponentsManagerRef}
                />
            </div>
        );
    }

    private setGlobalReactComponentsManagerRef = (instance: GlobalWrapperComponentsManager) => {
        if (instance) {
            this.globalReactComponentsManagerRef = instance;
            this.messageBroker.handleMesseageHandlerSetup(instance);
        }
    };

    private websocketHandleOnConnectError = async (error: any) => {
        this.globalReactComponentsManagerRef.showPopupMessage(new PopupMessageCreator({
            title: "Error",
            message: "Keine Verbindung zum Server!",
            headingType: "error"
        }).getMessage());
    };

    private websocketMessageCallBack = async (message: IncomingMessage) => {
        this.messageBroker.handleBackendMessage(message);
    };

    private changeScreen = async (screenGuid: string) => {
        const path: string = RoutingTableManager.getScreenPathByGuid(screenGuid);

        if (path) {
            if(window.location.pathname.includes(path)){
                // workaround to refresh the page without doing a full page reload with react router
                this.props.history.replace('/');
                this.props.history.replace(path);
            }
            else {
                Screen.setCurrentlyDisplayedScreenGuid(RoutingTableManager.getScreenGuidByScreenPath(path));
                this.props.history.push(path);
            }
        } else {
            throw new Error("No screen path matching the screenId: " + screenGuid);
        }
    };

    private messageBrokerApplicationEventHandlerCallBack = async <T extends Message>(message: T) => {
        this.messageBroker.handleFrontendMessage(message);
    };
}

export default withRouter(Application);
