import {MessageHandler} from "./MessageHandler";
import ChangeScreenMessage from "../Message/ChangeScreenMessage";
import SessionStoreManager from "../SessionStoreManager";

export default class ChangeScreenMessageHandler implements MessageHandler<ChangeScreenMessage> {

    private readonly onChangeScreenRequest: (screenGuid: string) => void;
    private readonly updateInitDataForModule: (
        componentGuid: string,
        componentsToUpdate: { [key: string]: any },
    ) => void;

    constructor(
        onChangeScreenRequest: (screenGuid: string) => void,
        updateInitDataForModule: (
            componentGuid: string,
            componentsToUpdate: { [key: string]: any }) => void,
    ) {
        this.onChangeScreenRequest = onChangeScreenRequest;
        this.updateInitDataForModule = updateInitDataForModule;
    }

    public handle(message: ChangeScreenMessage): void {
        this.handleUpdateInitDataAndChangeScreen(message);
    }

    private changeScreen(screenGuid: string): void {
        this.onChangeScreenRequest(screenGuid);
    }

    private handleUpdateInitDataAndChangeScreen(message: ChangeScreenMessage): void {
        if (message.componentsToUpdate && (Object.keys(message.componentsToUpdate).length > 0)) {
            try {
                this.updateInitData(message.componentsToUpdate);
            } catch (e) {
                // ignoring failed updates and
                // clear the sessionStorage in case the error occurs because of an old backup
                SessionStoreManager.clear();
            }

        }
        this.changeScreen(message.screenGuid);
    }

    private updateInitData(componentsToUpdate: { [key: string]: any }) {
        for (const componentGuid of Object.keys(componentsToUpdate)) {
            this.updateInitDataForModule(componentGuid, componentsToUpdate[componentGuid]);
        }
    }
}
