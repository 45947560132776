import {MessageHandler} from "./MessageHandler";
import ComponentReferenceStore from "../ComponentReferenceStore";
import UpdateComponentsMessage from "../Message/UpdateComponentsMessage";

export default class UpdateComponentsMessageHandler implements MessageHandler<UpdateComponentsMessage> {

    public handle(message: UpdateComponentsMessage): void {
        UpdateComponentsMessageHandler.updateComponents(message);
    }

    private static updateComponents(message: UpdateComponentsMessage) {
        for (const componentGuid of Object.keys(message.componentsToUpdate)) {
            ComponentReferenceStore.updateComponentContent(
                componentGuid,
                message.componentsToUpdate[componentGuid],
            );
        }
    }
}
