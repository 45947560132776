import * as React from "react";
import { withRouter } from "react-router-dom";

class NoneExistingRoutingHandler extends React.Component<any, any> {
    public render() {
        return (
            <div>
                <h1>This page does not Exist !</h1>
                <button onClick={this.handleClick}>
                    Return to previous page
                </button>
            </div>
        );
    }

    private handleClick = () => {
        this.props.history.goBack();
    }
}

export default withRouter(NoneExistingRoutingHandler);
