import * as React from "react";
import VayuModule from "../VayuModule";
import "./LoginButton.css";
import ComponentReferenceStore from "../ComponentReferenceStore";
import Ajax from "../Ajax";
import Button from "./Button";
import InvalidCsrfProtectionIdMessageCreator from "../Message/InvalidCsrfProtectionIdMessageCreator";
import LoginSuccessMessageCreator from "../Message/LoginSuccessMessageCreator";
import OutgoingMessageCreator from "../Message/OutgoingMessageCreator";

class LoginButton extends VayuModule {

    private refButton?: Button;

    private static async sendLoginRequest(
        username: string,
        password: string,
        csrfProtectionId: string,
    ): Promise<Response> {
        return await Ajax.sendRequestWithUrlEncodedParams(
            "POST",
            "https://" + window.location.host + "/execute_login",
            {
                password,
                username,
                csrf_protection_id: csrfProtectionId,
            },
        );
    }

    private static getCsrfProtectionId(): string {
        const metaTag: any = document.getElementById("request_authentication_ids");
        const csrfProtectionId: string | null = metaTag.getAttribute(
            "csrf_protection_id",
        );
        return (csrfProtectionId === null) ? "" : csrfProtectionId;
    }

    public render() {
        const classes = "LoginButton-container";

        return (
            <div className={classes}>
                <Button
                    ref={(ref: Button) => {
                        this.refButton = ref;
                    }}
                    onClick={this.handleLoginRequest}
                    initData={{text: this.state.text, type: this.state.type}}
                />
            </div>
        );
    }

    protected getDefaultState(): object {
        return {
            usernameReferenceId: "" as string,
            passwordReferenceId: "" as string,
            type: "secondary" as string,
            text: "Login" as string,
        };
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData, () => {
            if (this.refButton !== undefined) {
                this.refButton.onContentUpdate({
                    type: this.state.type,
                    text: this.state.text,
                });
            }
        });
    }

    protected handleLoginRequest = (
        button: Button,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const username: string = ComponentReferenceStore.getComponentContent(
            this.state.usernameReferenceId,
        );
        const password: string = ComponentReferenceStore.getComponentContent(
            this.state.passwordReferenceId,
        );
        const csrfProtectionId: string = LoginButton.getCsrfProtectionId();

        LoginButton.sendLoginRequest(username, password, csrfProtectionId)
            .then((response: Response) => {
                this.onLoginSuccess();
            })
            .catch((error: { status: number; statusText: string }) => {
                this.onLoginFail(error);
            });
    };

    private onLoginFail(error: { status: number; statusText: string }) {
        if (error.status === 400) {
            this.sendMessageToMessageBroker(new InvalidCsrfProtectionIdMessageCreator().getMessage());
        } else {
            Ajax.printMessageForStatusCode(error);
        }
    }

    private onLoginSuccess(): void {
        const outgoingMessageCreator = new OutgoingMessageCreator(this.screenGuid, {
                guid: this.componentGuid,
                eventType: "click",
                data: {}
            }
        );
        const loginSuccessMessageCreator = new LoginSuccessMessageCreator(
            outgoingMessageCreator.getMessage()
        );
        this.sendMessageToMessageBroker(
            loginSuccessMessageCreator.getMessage()
        );
    }
}

export default LoginButton;
