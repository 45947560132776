import {MessageHandler} from "./MessageHandler";
import DownloadFileMessage from "../Message/DownloadFileMessage";

export default class DownloadFileMessageHandler implements MessageHandler<DownloadFileMessage> {

    private downloadWithPostRequest: (fileGuid: string) => void;

    constructor(downloadWithPostRequest: (fileGuid: string) => void) {
        this.downloadWithPostRequest = downloadWithPostRequest;
    }

    public handle(message: DownloadFileMessage): void {
        this.downloadWithPostRequest(message.fileGuid);
    }

}
