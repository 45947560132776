import PopupMessage from "./PopupMessage";
import MessageCreator from "./MessageCreator";

export default class PopupMessageCreator implements MessageCreator<PopupMessage> {
    protected static readonly type: string = "popup";
    private message: PopupMessage;

    public constructor(popupMessage: PopupMessage) {
        popupMessage.type = PopupMessageCreator.type;
        this.message = popupMessage;
    }

    public getMessage(): PopupMessage {
        return this.message;
    }
}
