import * as React from "react";
import ObjectCloner from "./ObjectCloner";
import Message from "./Message/Message";
import OutgoingMessageCreator from "./Message/OutgoingMessageCreator";
import OutgoingMessage from "./Message/OutgoingMessage";

abstract class VayuModule extends React.Component<any, any> {

    protected static eventHandler: <T extends Message>(message: T) => Promise<void>;
    protected readonly componentGuid: string;
    protected readonly screenGuid: string;

    protected sendEventToBackend = (eventType: string, eventData: object = {}): void => {
        const message: OutgoingMessage = this.constructNewOutgoingMessage(eventType, eventData);
        VayuModule.eventHandler(message);
    };

    protected sendMessageToMessageBroker = <T extends Message>(message: T): void => {
        VayuModule.eventHandler(message);
    };

    protected constructor(props: any) {
        super(props);
        this.componentGuid = props.guid || " ";
        this.screenGuid = props.screenGuid;
        this.setInitialState();
    }

    protected abstract getDefaultState(): object;

    private setInitialState() {
        if (this.props.initData === undefined) {
            this.state = this.getDefaultState();
        } else {
            this.state = ObjectCloner.deepClone(this.props.initData);
        }
    }

    public static setEventHandler(eventHandler: <T extends Message>(message: T) => Promise<void>): void {
        VayuModule.eventHandler = eventHandler;
    }

    public onContentUpdate(newData: any): void {
        throw new Error("Override the onContentUpdate method !");
    }

    public getContent(): any {
        throw new Error("Override the getContent method !");
    }

    protected constructNewOutgoingMessage(eventType: string, eventData: object = {}): OutgoingMessage {
        return new OutgoingMessageCreator(
            this.screenGuid,
            {
                guid: this.componentGuid,
                eventType,
                data: eventData
            }
        ).getMessage();
    }
}

export default VayuModule;
