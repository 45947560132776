import * as React from "react";

class RedirectToApplicationWrapper extends React.Component<any, any> {
    private static getCsrfProtectionId(): string {
        const metaTag: any = document.getElementById(
            "request_authentication_ids",
        );
        const csrfProtectionId: string | null = metaTag.getAttribute(
            "csrf_protection_id",
        );
        return csrfProtectionId === null ? "" : csrfProtectionId;
    }

    private readonly formName: string = "redirect_to_application_with_post";

    protected constructor(props: any) {
        super(props);
        this.state = {
            url: "",
            postValue: "",
        };
    }

    public render() {
        const formName: string = this.formName;
        const crsfProtectionIdParamName: string = "csrf_protection_id";

        return (
            <form
                action={this.state.url}
                name={formName}
                method="post"
                style={{ display: "none" }}
            >
                <input
                    type="text"
                    name={crsfProtectionIdParamName}
                    value={this.state.postValue}
                    onChange={this.onChange}
                />
            </form>
        );
    }

    private onChange = (event: React.SyntheticEvent) => {
        event.preventDefault();
    }

    public componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any,
    ): void {
        if (this.state.url !== prevState.url) {
            // @ts-ignore
            document.forms[this.formName].submit();
        }
    }

    public redirect(appName: string) {
        const appUrl: string =
            "https://" + window.location.hostname + "/" + appName;
        this.setState({
            url: appUrl,
            postValue: RedirectToApplicationWrapper.getCsrfProtectionId(),
        });
    }
}

export default RedirectToApplicationWrapper;
