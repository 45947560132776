import {MessageHandler} from "./MessageHandler";
import OutgoingMessage from "../Message/OutgoingMessage";
import OutgoingMessageHandler from "./OutgoingMessageHandler";

export default class LoginSuccessMessageHandler implements MessageHandler<OutgoingMessage> {
    private outgoingMessageHandler: OutgoingMessageHandler;

    public constructor(outgoingMessageHandler: OutgoingMessageHandler) {
        this.outgoingMessageHandler = outgoingMessageHandler;
    }

    public handle(message: OutgoingMessage): void {
        // @TODO send force logout message to ws in case a new user logs in
        this.outgoingMessageHandler.handle(message);
    }
}
