export default class LoginHandler {

    public static forceNewLogin() {
        const appName = window.location.pathname.split("/", 2)[1];

        let uri = 'login';

        if(appName !== undefined && appName.toLocaleLowerCase() !== 'login'){
            uri = appName + "/" + uri;
        }

        const url = window.location.origin + "/" + uri;
        window.location.replace(url);
    }
}
