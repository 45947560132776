import {MessageHandler} from "./MessageHandler";
import LoadingScreenMessage from "../Message/LoadingScreenMessage";

export default class LoadingScreenMessageHandler implements MessageHandler<LoadingScreenMessage> {
    private handleLoadingScreenMessage: (loadingScreenMessage: LoadingScreenMessage) => void;

    constructor(handleLoadingScreenMessage: (loadingScreenMessage: LoadingScreenMessage) => void) {
        this.handleLoadingScreenMessage = handleLoadingScreenMessage;
    }

    public handle(message: LoadingScreenMessage): void {
        this.handleLoadingScreenMessage(message);
    }
}
