import {MessageHandler} from "./MessageHandler";
import WebsocketClient from "../WebsocketClient";
import OutgoingMessage from "../Message/OutgoingMessage";

export default class OutgoingMessageHandler implements MessageHandler<OutgoingMessage> {

    private websocketClient: WebsocketClient;

    public constructor(websocketClient: WebsocketClient) {
        this.websocketClient = websocketClient;
    }

    public handle(message: OutgoingMessage): void {
        this.handleOutgoingMessage(message);
    }

    private async handleOutgoingMessage(message: OutgoingMessage) {

        OutgoingMessageHandler.replaceFrontendTypeWithBackendType(message);

        if (await this.connect()) {
            this.websocketClient.sendDataToServer(message);
        }
    }

    private async connect(): Promise<boolean> {
        return (
            this.websocketClient.isConnected() ||
            (await this.websocketClient.start())
        );
    }

    private static replaceFrontendTypeWithBackendType(message: OutgoingMessage) {
        message.type = "application";
    }
}
