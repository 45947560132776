import {MessageHandler} from "./MessageHandler";
import FetchDataMessage from "../Message/FetchDataMessage";
import ComponentReferenceStore from "../ComponentReferenceStore";
import OutgoingMessageCreator from "../Message/OutgoingMessageCreator";
import OutgoingMessageHandler from "./OutgoingMessageHandler";

export default class FetchDataMessageHandler implements MessageHandler<FetchDataMessage> {

    private readonly outgoingMessageHandler: OutgoingMessageHandler;

    constructor(
        outgoingMessageHandler: OutgoingMessageHandler,
    ) {
        this.outgoingMessageHandler = outgoingMessageHandler;

    }

    public handle(message: FetchDataMessage): void {
        this.handleFetchData(message);
    }

    private handleFetchData(parsedMessage: FetchDataMessage) {

        const fetchedData: { [key: string]: any } = {};

        for (const moduleInstanceId of parsedMessage.applicationModuleInstanceIdsToFetchDataFrom) {
            fetchedData[moduleInstanceId] = ComponentReferenceStore.getComponentContent(moduleInstanceId);
        }

        const outgoingMessageCreator = new OutgoingMessageCreator(parsedMessage.screenIdToTriggerFetchedEventFrom, {
            guid: parsedMessage.applicationModuleInstanceIdToTriggerFetchedEventFrom,
            eventType: "sendRequestedData",
            data: fetchedData,
        });

        this.outgoingMessageHandler.handle(outgoingMessageCreator.getMessage());
    }

}
