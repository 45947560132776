import VayuModule from "./VayuModule";

class ComponentReferenceStore {
    public static updateComponentContent(
        childGUID: string,
        updateContent: any,
    ): void {
        const childReference: VayuModule = ComponentReferenceStore.getComponentReference(
            childGUID,
        );
        childReference.onContentUpdate(updateContent);
    }

    public static clear() {
        ComponentReferenceStore.componentReferences = new Map();
    }

    public static getComponentContent(componentGuid: string): any {
        const childReference: VayuModule = ComponentReferenceStore.getComponentReference(
            componentGuid,
        );
        return childReference.getContent();
    }

    public static getComponentState(componentGuid: string): object {
        const childReference: VayuModule = ComponentReferenceStore.getComponentReference(
            componentGuid,
        );
        return JSON.parse(JSON.stringify(childReference.state));
    }

    public static getComponentsStateMap(): { [key: string]: object } {
        const componentsStateMap: { [key: string]: object } = {};
        for (const [k, v] of ComponentReferenceStore.componentReferences) {
            componentsStateMap[k] = JSON.parse(JSON.stringify(v.state));
        }
        return componentsStateMap;
    }

    public static addComponentReference(
        componentGuid: string,
        componentReference: VayuModule,
    ) {
        ComponentReferenceStore.componentReferences.set(
            componentGuid,
            componentReference,
        );
    }

    public static addComponentReferences(
        componentReferences: Map<string, VayuModule>,
    ): any {
        for (const [k, v] of componentReferences) {
            ComponentReferenceStore.addComponentReference(k, v);
        }
    }

    private static componentReferences: Map<string, VayuModule> = new Map();

    // never call this during the render process
    private static getComponentReference(childGUID: string): VayuModule {

        let childReference: any;

        try {
            childReference = ComponentReferenceStore.componentReferences.get(
                childGUID,
            );

            if (childReference === undefined) {
                throw new Error(
                    "No module found with the GUID : " + childGUID + " !",
                );
            }

            return childReference;

        } catch (e) {
            throw new Error(
                "No module found with the GUID : " + childGUID + " !",
            );
        }
    }
}

export default ComponentReferenceStore;
