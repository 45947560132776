import {MessageHandler} from "./MessageHandler";
import Message from "../Message/Message";
import PopupMessageHandler from "./PopupMessageHandler";
import PopupMessageCreator from "../Message/PopupMessageCreator";
import LoginHandler from "../LoginHandler";

export default class SessionExpiredMessageHandler implements MessageHandler<Message> {
    private popupMessageHandler: PopupMessageHandler;

    public constructor(popupMessageHandler: PopupMessageHandler) {
        this.popupMessageHandler = popupMessageHandler;
    }

    public handle(message?: Message): void {
        this.handleInvalidSession();
    }

    private handleInvalidSession() {

        const onConfirm = () => {
            LoginHandler.forceNewLogin();
        };

        const popupMessageCreator = new PopupMessageCreator({
            title: "Information",
            message: "Die Sitzung ist abgelaufen, wollen Sie sich erneut anmelden ?",
            onConfirm
        });

        this.popupMessageHandler.handle(popupMessageCreator.getMessage());
    }
}
