import * as React from "react";
import Screen from "./Modules/Screen";

class DownloadWithPostRequestWrapper extends React.Component<any, any> {

    private readonly formName: string = "download_file_with_post_request";

    private static getCsrfProtectionId(): string {
        const metaTag: any = document.getElementById(
            "request_authentication_ids",
        );
        const csrfProtectionId: string | null = metaTag.getAttribute(
            "csrf_protection_id",
        );
        return csrfProtectionId === null ? "" : csrfProtectionId;
    }

    protected constructor(props: any) {
        super(props);
        this.state = {
            url: "",
            screenId: "",
            fileId: "",
            csrfProtectionId: "",
        };
    }

    public render() {
        const formName: string = this.formName;

        return (
            <form
                action={this.state.url}
                name={formName}
                method="post"
                encType={"multipart/form-data"}
                style={{display: "none"}}
            >
                <input
                    type="text"
                    name="screenId"
                    value={this.state.screenId}
                    onChange={this.onChange}
                />
                <input
                    type="text"
                    name="fileId"
                    value={this.state.fileId}
                    onChange={this.onChange}
                />
                <input
                    type="text"
                    name="csrf_protection_id"
                    value={this.state.csrfProtectionId}
                    onChange={this.onChange}
                />
            </form>
        );
    }

    private onChange = (event: React.SyntheticEvent) => {
        event.preventDefault();
    }

    public componentDidUpdate(
        prevProps: Readonly<any>,
        prevState: Readonly<any>,
        snapshot?: any,
    ): void {
        if (this.state.fileId !== prevState.fileId) {
            // @ts-ignore
            document.forms[this.formName].submit();
        }
    }

    public downloadFile(fileGuid: string) {
        const appUrl: string =
            "https://" + window.location.hostname + "/download_file";
        this.setState({
            url: appUrl,
            screenId: Screen.getCurrentlyDisplayedScreenGuid(),
            fileId: fileGuid,
            csrfProtectionId: DownloadWithPostRequestWrapper.getCsrfProtectionId(),
        });
    }
}

export default DownloadWithPostRequestWrapper;
