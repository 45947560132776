import {MessageHandler} from "./MessageHandler";
import PopupMessage from "../Message/PopupMessage";

export default class PopupMessageHandler implements MessageHandler<PopupMessage> {
    private readonly handleShowPopup: (popupMessage: PopupMessage) => void;

    constructor(handleShowPopup: (popupMessage: PopupMessage) => void) {
        this.handleShowPopup = handleShowPopup;
    }

    public handle(message: PopupMessage): void {
        this.handleShowPopup(message) ;
    }
}
