import {MessageHandler} from "./MessageHandler";
import Message from "../Message/Message";
import PopupMessageHandler from "./PopupMessageHandler";
import PopupMessageCreator from "../Message/PopupMessageCreator";
import LoginHandler from "../LoginHandler";

export default class UnauthorizedSessionMessageHandler implements MessageHandler<Message> {

    private popupMessageHandler: PopupMessageHandler;

    public constructor(popupMessageHandler: PopupMessageHandler) {
        this.popupMessageHandler = popupMessageHandler;
    }

    public handle(message?: Message): void {
        this.handleUnauthorizedSession();
    }

    private handleUnauthorizedSession() {

        const onConfirm = () => {
            LoginHandler.forceNewLogin();
        };

        const popupMessageCreator = new PopupMessageCreator({
            title: "Information",
            message: "Die Sitzung ist nicht mehr authorisiert,\ndie seite wird " +
                "jetzt neu geladen danach können Sie sich wieder anmelden!",
            onConfirm
        });

        this.popupMessageHandler.handle(popupMessageCreator.getMessage());
    }

}
