import * as React from "react";
import VayuContainerModule from "../VayuContainerModule";
import "./HorizontalContainer.css";

class HorizontalContainer extends VayuContainerModule {
    public render() {
        const styleContainer = {
            width: this.state.width,
            marginTop: this.state.margin[0],
            marginLeft: this.state.margin[1],
            marginBottom: this.state.margin[2],
            marginRight: this.state.margin[3],
            paddingTop: this.state.padding[0],
            paddingLeft: this.state.padding[1],
            paddingBottom: this.state.padding[2],
            paddingRight: this.state.padding[3],
        };
        const children: JSX.Element[] = this.renderChildren();
        return (
            <div
                key={this.componentGuid}
                style={styleContainer}
                className={
                    "horizontal-container vayu-container " + this.componentGuid + " row"
                }
            >
                {this.wrapChildren(children)}
            </div>
        );
    }

    protected getDefaultState(): object {
        return {};
    }

    protected appendWrappedChild(
        childIndex: number,
        allocatedChildren: JSX.Element[],
        child: JSX.Element,
    ): void {
        const styleNode = {
            marginTop: this.state.nodes[childIndex].margin[0],
            marginLeft: this.state.nodes[childIndex].margin[1],
            marginBottom: this.state.nodes[childIndex].margin[2],
            marginRight: this.state.nodes[childIndex].margin[3],
            paddingTop: this.state.nodes[childIndex].padding[0],
            paddingLeft: this.state.nodes[childIndex].padding[1],
            paddingBottom: this.state.nodes[childIndex].padding[2],
            paddingRight: this.state.nodes[childIndex].padding[3],
        };

        const horizontalWrapperCss: string =
            "horizontal-wrapper " +
            child.props.guid +
            " col-md-" +
            this.state.nodes[childIndex].width +
            (this.state.nodes[childIndex].invisible ? " not-visible" : "");

        allocatedChildren.push(
            <div
                key={child.props.guid + "_horizontalWrapper_node_" + childIndex}
                className={horizontalWrapperCss}
            >
                <div
                    className={
                        "horizontal-node vayu-container-node " +
                        this.state.nodes[childIndex].align
                    }
                    style={styleNode}
                >
                    {child}
                </div>
            </div>,
        );
    }

    private wrapChildren(children: JSX.Element[]): JSX.Element[] {
        const wrappedChildren: JSX.Element[] = [];
        let i = 0;
        for (const child of children) {
            this.appendWrappedChild(i, wrappedChildren, child);
            i++;
        }
        return wrappedChildren;
    }
}

export default HorizontalContainer;
