import {routingTable} from "./RoutingTable";

export default class RoutingTableManager {

    public static getScreenPathByGuid(screenGuid: string): string {
        return routingTable[screenGuid];
    }

    public static getAllScreenGuids(): string[] {
        return Object.keys(routingTable);
    }

    public static getScreenGuidByScreenPath(path: string): string {
        // @TODO add another dictionary so that we can check if a route exists directly
        // terrible performance and redundant
        for (const screenGuid of Object.keys(routingTable)) {
            if (routingTable[screenGuid].toLocaleLowerCase() === path.toLocaleLowerCase()) {
                return screenGuid;
            }
        }

        // fallback to root screen
        path = RoutingTableManager.getCurrentAppName();

        for (const screenGuid of Object.keys(routingTable)) {
            if (routingTable[screenGuid].toLocaleLowerCase() === path.toLocaleLowerCase()) {
                return screenGuid;
            }
        }

        throw new Error("Invalid application, no login and no root screen path found");
    }

    public static getCurrentScreenGuid(): string {
        return RoutingTableManager.getScreenGuidByScreenPath(RoutingTableManager.getFullPathOfCurrentScreen());
    }

    public static getFullPathOfCurrentScreen(): string {
        const appName: string = RoutingTableManager.getCurrentAppName();
        const screenName: string = RoutingTableManager.getCurrentScreenName();

        let pathToLoginScreen: string = "/" + appName;

        if (screenName !== "") {
            pathToLoginScreen += "/" + screenName;
        }

        return pathToLoginScreen;
    }

    public static getScreenGuidOfCurrentScreen(): string {
        const pathToCurrentScreen: string = window.location.pathname;
        return RoutingTableManager.getScreenGuidByScreenPath(pathToCurrentScreen);
    }

    public static getCurrentAppName(): string {
        const appName: string | undefined = window.location.pathname.split("/", 2)[1];
        if (appName === undefined) {
            throw new Error("Invalid app name");
        } else {
            return appName;
        }
    }

    public static getCurrentScreenName(): string {
        const pathParts = window.location.pathname.split("/", 3) || [];
        if (pathParts.length === 3) {
            return pathParts[2];
        } else {
            return "";
        }
    }

    public static doesScreenWithPathExist(path: string) {

        // @TODO add another dictionary so that we can check if a route exists directly
        for (const screenGuid of Object.keys(routingTable)) {
            if (routingTable[screenGuid].toLocaleLowerCase() === path.toLocaleLowerCase()) {
                return true;
            }
        }

        return false;
    }
}
