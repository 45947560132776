import * as React from "react";
import VayuModule from "../VayuModule";
import "./TextHTML.css";

class TextHTML extends VayuModule {
    public render() {
        return <div className="TextHTML-container" dangerouslySetInnerHTML={{__html: this.getHTML()}}/>;
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData);
    }

    public getContent(): any {
        return this.state;
    }

    protected getDefaultState(): object {
        return {
            content: "",
        };
    }

    private getHTML(): string {
        const doc: Document = new DOMParser().parseFromString(this.state.content, "text/xml");
        const check: boolean = this.iterateHTMLNodes(doc.childNodes);
        if (check) {
            return this.state.content;
        } else {
            return "<b>Invalide content received!</b>";
        }
    }

    private iterateHTMLNodes(childrenNodes: NodeListOf<ChildNode>): boolean {
        for (const childNode of childrenNodes) {
            console.log(childNode);
            // @ts-ignore
            if (!this.verifyTagName(childNode.tagName)) {
                return false;
            }
            if (!this.iterateHTMLNodes(childNode.childNodes)) {
                return false;
            }
        }
        return true;
    }

    private verifyTagName(tagName: string): boolean {
        // undefined is plain text
        return tagName === undefined ||
            tagName === "br" ||
            tagName === "b" ||
            tagName === "i" ||
            tagName === "u" ||
            tagName === "p" ||
            tagName === "div" ||
            tagName === "span" ||
            tagName === "h1" ||
            tagName === "h2" ||
            tagName === "h3";

    }
}

export default TextHTML;
