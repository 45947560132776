import VayuModule from "./VayuModule";

abstract class VayuSuperModule extends VayuModule {
    // the references are being created after render (but before mounting),
    // so they have to be added to the store in the ref method
    protected addChildReference: any = (instance: VayuModule) => {
        if (instance) {
            this.addComponentReference(instance.props.guid, instance);
        }
    }

    protected updateComponentContent(
        childGUID: string,
        updateContent: any,
    ): void {
        const childReference: VayuModule = this.getComponentReference(
            childGUID,
        );
        childReference.onContentUpdate(updateContent);
    }

    protected clear() {
        this.componentReferences = new Map();
    }

    protected getComponentContent(childGUID: string): any {
        const childReference: VayuModule = this.getComponentReference(
            childGUID,
        );
        return childReference.getContent();
    }

    protected addComponentReference(
        componentGuid: string,
        componentReference: VayuModule,
    ) {
        this.componentReferences.set(componentGuid, componentReference);
    }

    protected addComponentReferences(
        componentReferences: Map<string, VayuModule>,
    ): any {
        for (const [k, v] of componentReferences) {
            this.addComponentReference(k, v);
        }
    }

    private componentReferences: Map<string, VayuModule> = new Map();

    // never call this during the render process
    protected getComponentReference(childGUID: string): VayuModule {

        let childReference: any;

        try {
            childReference = this.componentReferences.get(
                childGUID,
            );

            if (childReference === undefined) {
                throw new Error(
                    "No module found with the GUID : " + childGUID + " !",
                );
            }

            return childReference;

        } catch (e) {
            throw new Error(
                "No module found with the GUID : " + childGUID + " !",
            );
        }
    }
}

export default VayuSuperModule;
