import VayuModule from "./VayuModule";
import RenderTablesHandler from "./RenderTable";
import ComponentReferenceStore from "./ComponentReferenceStore";
import * as React from "react";
import ObjectCloner from "./ObjectCloner";

abstract class VayuContainerModule extends VayuModule {
    // the references are being created after render (but before mounting),
    // so they have to be added to the store in the ref method
    private static addChildReference: any = (instance: VayuModule) => {
        if (instance) {
            ComponentReferenceStore.addComponentReference(
                instance.props.guid,
                instance,
            );
        }
    }

    protected renderChildren(): JSX.Element[] {
        let VayuComponent: any;
        let initData: any;
        const children: JSX.Element[] = [];
        const renderMapData: any[] = RenderTablesHandler.getRenderMapByComponentGuid(
            this.componentGuid,
        );
        const numberOfComponentsToRender: number = renderMapData.length;

        for (
            let componentIndex = 0;
            componentIndex < numberOfComponentsToRender;
            componentIndex++
        ) {
            const guid = renderMapData[componentIndex].guid;
            initData = ObjectCloner.deepClone(
                renderMapData[componentIndex].initData,
            );
            initData = Array.isArray(initData) ? {} : initData;
            // backend json encode will convert empty objects to arrays

            VayuComponent = RenderTablesHandler.getComponentTypeByParentGuidAndComponentPosition(
                this.componentGuid,
                componentIndex,
            );

            children.push(
                (
                    <VayuComponent
                        ref={VayuContainerModule.addChildReference}
                        key={guid}
                        initData={initData}
                        guid={guid}
                        screenGuid={this.screenGuid}
                    />
                ),
            );
        }
        return children;
    }
}

export default VayuContainerModule;
