/* tslint:disable:max-line-length */
import PopupMessage from "./Message/PopupMessage";

class Ajax {

    private static handleShowPopup: (popupMessage: PopupMessage) => void;

    public static setShowPopupHandler(handleShowPopup: (popupMessage: PopupMessage) => void): void {
        Ajax.handleShowPopup = handleShowPopup;
    }

    public static async sendPostRequestWithJsonEncodedParams(
        url: string,
        params: object = {},
    ): Promise<Response> {
        const encodedParams: string = JSON.stringify(params);
        const headers = {
            "Content-type": "application/json; charset=utf-8",
        };
        return await Ajax.sendAjax("POST", url, headers, encodedParams);
    }

    public static async sendRequestWithUrlEncodedParams(
        httpMethod: string,
        url: string,
        params: { [key: string]: string } = {},
    ): Promise<Response> {
        let urlEncodedString: string;
        const headers = {"Content-type": "application/x-www-form-urlencoded"};
        const urlParams: string[] = [];

        for (const paramName of Object.keys(params)) {
            urlParams.push(
                paramName + "=" + encodeURIComponent(params[paramName]),
            );
        }

        urlEncodedString = urlParams.join("&");

        return await Ajax.sendAjax(httpMethod, url, headers, urlEncodedString);
    }

    public static async sendFormData(
        url: string,
        formData: FormData,
    ): Promise<Response> {
        return await Ajax.sendAjax("POST", url, {}, formData);
    }

    public static async sendRequestWithoutParams(
        httpMethod: string,
        url: string,
        headers: object = {},
    ): Promise<Response> {
        return await Ajax.sendAjax(httpMethod, url, headers);
    }

    public static async sendAjax(
        method: string,
        url: string,
        headers: any = {},
        data: any = "",
    ): Promise<Response> {
        return await new Promise<Response>((resolve, reject) => {
            const xhr: XMLHttpRequest = new XMLHttpRequest();
            xhr.open(method, url, true);

            xhr.onload = () => {
                if (Ajax.wasRequestSuccessful(xhr)) {
                    resolve(xhr.response);
                } else {
                    reject({status: xhr.status, statusText: xhr.statusText});
                }
            };

            xhr.onerror = () => {
                reject({status: xhr.status, statusText: xhr.statusText});
            };

            for (const headerName of Object.keys(headers)) {
                xhr.setRequestHeader(headerName, headers[headerName]);
            }

            xhr.send(data);
        });
    }

    public static printMessageForStatusCode(errorStatus: {
        status: number;
        statusText: string;
    }): void {

        const messages: { [key: number]: string } = {
            401: "Ungültiger Benuternahme oder Passwort",
            400: "Ungültige Anfrage",
            0: "Keine Verbindung zum Server möglich!",
            500: "Server Error, versuchen Sie es in ein paar Sekunden erneut\n oder kontaktieren sie ihren Administrator!",
            10000: "Unbekanter http Fehler CODE:" + errorStatus.status
        };

        const defaultErrorCode: number = 10000;

        const popupMessage = {
            title: "Error",
            message: messages[errorStatus.status] || messages[defaultErrorCode]
        };

        Ajax.handleShowPopup(popupMessage);
    }

    private static wasRequestSuccessful(xhr: XMLHttpRequest): boolean {
        return xhr.status >= 200 && xhr.status <= 308;
    }
}

export default Ajax;
