import PopupMessage from "../Message/PopupMessage";
import OutgoingMessageHandler from "./OutgoingMessageHandler";
import OutgoingMessageCreator from "../Message/OutgoingMessageCreator";
import Screen from "../Modules/Screen";
import BackendPopupMessage from "../Message/BackendPopupMessage";
import {MessageHandler} from "./MessageHandler";

export default class BackendPopupMessageHandler implements MessageHandler<BackendPopupMessage> {

    private outgoingMessageHandler: OutgoingMessageHandler;
    private handleShowPopup: (popupMessage: PopupMessage) => void;

    // tslint:disable-next-line:max-line-length
    public constructor(handleShowPopup: (popupMessage: PopupMessage) => void, outgoingMessageHandler: OutgoingMessageHandler) {
        this.handleShowPopup = handleShowPopup;
        this.outgoingMessageHandler = outgoingMessageHandler;
    }

    public handle(message: BackendPopupMessage): void {

        if (message.onCancelEventName) {
            this.setOnCancelCallback(message);
        }

        if (message.onConfirmEventName) {
            this.setOnConfirmCallBack(message);
        }

        this.handleShowPopup(message);
    }

    private setOnCancelCallback(message: BackendPopupMessage): void {

        const screenGuid: string = Screen.getCurrentlyDisplayedScreenGuid();
        const outgoingMessageCreator = new OutgoingMessageCreator(screenGuid, {
            guid: screenGuid,
            eventType: message.onCancelEventName || "",
            data: {}
        });

        message.onCancel = () => {
            this.outgoingMessageHandler.handle(outgoingMessageCreator.getMessage());
        };
    }

    private setOnConfirmCallBack(message: BackendPopupMessage): void {

        const screenGuid: string = Screen.getCurrentlyDisplayedScreenGuid();
        const outgoingMessageCreator = new OutgoingMessageCreator(screenGuid, {
            guid: screenGuid,
            eventType: message.onConfirmEventName || "",
            data: {}
        });

        message.onConfirm = () => {
            this.outgoingMessageHandler.handle(outgoingMessageCreator.getMessage());
        };
    }
}
