import * as React from "react";
import RedirectToApplicationWrapper from "./RedirectToApplicationWrapper";
import DownloadWithPostRequestWrapper from "./DownloadWithPostRequestWrapper";
import PopupWrapper from "./PopupWrapper";
import PopupMessage from "./Message/PopupMessage";
import LoadingScreenWrapper from "./LoadingScreenWrapper";
import LoadingScreenMessage from "./Message/LoadingScreenMessage";

export default class GlobalWrapperComponentsManager extends React.Component<any, any> {

    private redirectToApplicationWrapper!: RedirectToApplicationWrapper;
    private downloadWithPostRequestWrapper!: DownloadWithPostRequestWrapper;
    private popupWrapper!: PopupWrapper;
    private loadingScreenWrapper!: LoadingScreenWrapper;

    public downloadFile = (fileId: string): void => {
        this.downloadWithPostRequestWrapper.downloadFile(fileId);
    };

    public redirectToApplication = (appName: string): void => {
        this.redirectToApplicationWrapper.redirect(appName);
    };

    public showPopupMessage = (popupMessage: PopupMessage): void => {
        this.popupWrapper.newPopup(popupMessage);
    };

    public handleLoadingScreenMessage = (loadingScreenMessage: LoadingScreenMessage): void => {
        this.loadingScreenWrapper.handleLoadingScreenMessage(loadingScreenMessage);
    };

    public render() {
        return (
            <div>
                <RedirectToApplicationWrapper
                    ref={this.setRedirectToApplicationWrapperRef}
                />
                <DownloadWithPostRequestWrapper
                    ref={this.setDownloadWithPostRequestWrapperRef}
                />
                <PopupWrapper
                    ref={this.setPopupWrapperRef}
                />
                <LoadingScreenWrapper
                    ref={this.setLoadingScreenWrapperRef}
                />
            </div>
        );
    }

    private setRedirectToApplicationWrapperRef = (
        instance: RedirectToApplicationWrapper,
    ) => {
        if (instance) {
            this.redirectToApplicationWrapper = instance;
        }
    };

    private setDownloadWithPostRequestWrapperRef = (
        instance: DownloadWithPostRequestWrapper,
    ) => {
        if (instance) {
            this.downloadWithPostRequestWrapper = instance;
        }
    };

    private setPopupWrapperRef = (
        instance: PopupWrapper,
    ) => {
        if (instance) {
            this.popupWrapper = instance;
        }
    };

    private setLoadingScreenWrapperRef = (
        instance: LoadingScreenWrapper
    ) => {
        this.loadingScreenWrapper = instance;
    };
}
