class SessionStoreManager {

    public static saveValueToSessionStore(
        key: string,
        value: object | string | number | { [key: string]: object }
    ): void {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static readValueFromSessionStore(key: string): any {
        const result: string = window.sessionStorage.getItem(key) || "null";
        return JSON.parse(result);
    }

    public static removeValueFromSessionStore(key: string): void {
        window.sessionStorage.removeItem(key);
    }

    public static doesKeyExist(key: string): boolean {
        return window.sessionStorage.getItem(key) !== null;
    }

    public static clear(): void {
        window.sessionStorage.clear();
    }
}

export default SessionStoreManager;
