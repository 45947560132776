import WebsocketClient from "./WebsocketClient";
import IncomingMessage from "./Message/IncomingMessage";
import ChangeScreenMessageHandler from "./MessageHandler/ChangeScreenMessageHandler";
import UpdateComponentsMessageHandler from "./MessageHandler/UpdateComponentsMessageHandler";
import RedirectToApplicationMessageHandler from "./MessageHandler/RedirectToApplicationMessageHandler";
import {MessageHandler} from "./MessageHandler/MessageHandler";
import RenderTablesHandler from "./RenderTable";
import InvalidCsrfProtectionIdMessageHandler from "./MessageHandler/InvalidCsrfProtectionIdMessageHandler";
import OutgoingMessageHandler from "./MessageHandler/OutgoingMessageHandler";
import LoginSuccessMessageHandler from "./MessageHandler/LoginSuccessMessageHandler";
import Message from "./Message/Message";
import DownloadFileMessageHandler from "./MessageHandler/DownloadFileMessageHandler";
import FetchDataMessageHandler from "./MessageHandler/FetchDataMessageHandler";
import FetchStateMessageHandler from "./MessageHandler/FetchStateMessageHandler";
import OpenEmailTemplateMessageHandler from "./MessageHandler/OpenEmailTemplateMessageHandler";
import RedirectToUrlMessageHandler from "./MessageHandler/RedirectToUrlMessageHandler";
import UnauthorizedSessionMessageHandler from "./MessageHandler/UnauthorizedSessionMessageHandler";
import SessionExpiredMessageHandler from "./MessageHandler/SessionExpiredMessageHandler";
import PopupMessageHandler from "./MessageHandler/PopupMessageHandler";
import BackendPopupMessageHandler from "./MessageHandler/BackendPopupMessageHandler";
import GlobalWrapperComponentsManager from "./GlobalWrapperComponentsManager";
import LoadingScreenMessageHandler from "./MessageHandler/LoadingScreenMessageHandler";
import UnauthorizedUserMessageHandler from "./MessageHandler/UnauthorizedUserMessageHandler";

export default class MessageBroker {

    private sharedMessageHandlers!: { [key: string]: MessageHandler<object> };
    private frontendMessageHandlers!: { [key: string]: MessageHandler<object> };
    private backendMessageHandlers!: { [key: string]: MessageHandler<object> };

    private readonly websocketClient: WebsocketClient;
    private readonly onChangeScreenRequestHandler: (path: string) => void;

    constructor(
        websocketClient: WebsocketClient,
        onChangeScreenRequestHandler: (path: string) => void
    ) {
        this.websocketClient = websocketClient;
        this.onChangeScreenRequestHandler = onChangeScreenRequestHandler;
    }

    public handleMesseageHandlerSetup(globalWrapperComponentsManager: GlobalWrapperComponentsManager): void {

        this.setupMessageHandlers(globalWrapperComponentsManager);
    }

    private setupMessageHandlers(globalWrapperComponentsManager: GlobalWrapperComponentsManager): void {

        const outgoingMessageHandler = new OutgoingMessageHandler(this.websocketClient);
        const changeScreenMessageHandler = new ChangeScreenMessageHandler(
            this.onChangeScreenRequestHandler, RenderTablesHandler.updateInitDataForModule
        );
        const redirectToApplicationMessageHandler = new RedirectToApplicationMessageHandler(
            globalWrapperComponentsManager.redirectToApplication
        );

        const popupMessageHandler = new PopupMessageHandler(globalWrapperComponentsManager.showPopupMessage);

        this.sharedMessageHandlers = {
            change_screen: changeScreenMessageHandler,
            redirect_to_application: redirectToApplicationMessageHandler,
            update_components: new UpdateComponentsMessageHandler(),
            invalid_csrf_protection_id: new InvalidCsrfProtectionIdMessageHandler(popupMessageHandler),
            send_mail: new OpenEmailTemplateMessageHandler(),
            redirect_to_url: new RedirectToUrlMessageHandler(),
            show_loading_screen: new LoadingScreenMessageHandler(
                globalWrapperComponentsManager.handleLoadingScreenMessage
            )
        };

        this.frontendMessageHandlers = {
            // tslint:disable-next-line:max-line-length
            login_success: new LoginSuccessMessageHandler(outgoingMessageHandler),
            outgoing: outgoingMessageHandler,
            popup: popupMessageHandler
        };

        this.backendMessageHandlers = {
            download_file: new DownloadFileMessageHandler(globalWrapperComponentsManager.downloadFile),
            fetch_data: new FetchDataMessageHandler(outgoingMessageHandler),
            fetch_state: new FetchStateMessageHandler(outgoingMessageHandler),
            unauthorized_session: new UnauthorizedSessionMessageHandler(popupMessageHandler),
            unauthorized_user: new UnauthorizedUserMessageHandler(popupMessageHandler),
            session_expired: new SessionExpiredMessageHandler(popupMessageHandler),
            // tslint:disable-next-line:max-line-length
            popup: new BackendPopupMessageHandler(globalWrapperComponentsManager.showPopupMessage, outgoingMessageHandler)
        };
    }

    public async handleFrontendMessage<T extends Message>(message: T) {
        const messageType = message.type || "";
        // tslint:disable-next-line:max-line-length
        const messageHandler: MessageHandler<T> = this.sharedMessageHandlers[messageType] || this.frontendMessageHandlers[messageType];
        messageHandler.handle(message);
    }

    public async handleBackendMessage(message: IncomingMessage) {
        const messageType = message.type || "";
        // tslint:disable-next-line:max-line-length
        const messageHandler: MessageHandler<object> = this.sharedMessageHandlers[messageType] || this.backendMessageHandlers[messageType];
        messageHandler.handle(message.data);
    }

}
