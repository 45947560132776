import * as React from "react";
import {Route, Switch} from "react-router-dom";

import RoutingTableManager from "./RoutingTableManager";
import NoneExistingRoutingHandler from "./NoneExistingRoutingHandler";
import LoadingScreen from "./LoadingScreen";
import RenderTablesHandler from "./RenderTable";
import ObjectCloner from "./ObjectCloner";

class ComponentRouter extends React.Component<any, any> {

    public render() {
        return <Switch>{this.renderRoutes()}</Switch>;
    }

    private renderRoutes() {
        const routes: JSX.Element[] = [];

        routes.push(
            <Route exact={true} path="/" key={"/"} component={LoadingScreen}/>,
        );

        for (const screenGuid of RoutingTableManager.getAllScreenGuids()) {
            this.addRouteRule(screenGuid, routes);
        }

        routes.push(
            <Route key={"default"} component={NoneExistingRoutingHandler}/>,
        );
        return routes;
    }

    private addRouteRule(screenGuid: string, routes: JSX.Element[]): void {
        const path: string = RoutingTableManager.getScreenPathByGuid(
            screenGuid,
        );
        const renderedComponent = () => this.renderNewRoute(screenGuid);
        routes.push(
            <Route exact={true} path={path} key={path} render={renderedComponent}/>,
        );
    }

    private renderNewRoute(screenGuid: string): JSX.Element {
        // unresolvable circular dependency workaround
        const Screen = RenderTablesHandler.getComponentCircularDependencySave(
            "Screen",
        );
        const renderMap: any[] = RenderTablesHandler.getRenderMapByComponentGuid(
            screenGuid,
        );

        let initData: object = ObjectCloner.deepClone(renderMap[0].initData);
        initData = Array.isArray(initData) ? {} : initData; // backend json encode will convert empty objects to arrays

        // only instance ids are mapped to workflows thus the guid cant be the screen guid
        // the screen guid is still important for the onDocumentReady event
        const screenInstanceId: string = renderMap[0].guid;

        return (
            <Screen
                key={screenGuid}
                guid={screenInstanceId}
                screenGuid={screenGuid}
                initData={initData}
            />
        );
    }
}

export default ComponentRouter;
