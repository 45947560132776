import * as React from "react";
import "./Tile.css";
import VayuModule from "../VayuModule";

class Tile extends VayuModule {
    public render() {
        const tileStyle:any = {
            backgroundColor:this.state.tileColor,
            borderRadius: this.state.borderRadius,
        };
        return (
            <div
                onMouseEnter={this.mouseEnter}
                className="Tile"
                onClick={this.onClick}
            >
                <a style={tileStyle}>{this.state.label}</a>
            </div>
        );
    }

    protected getDefaultState(): object {
        return {
            label: "empty",
            value: "empty",
            borderRadius: "3px",
            tileColor: "#5a7fae",
        };
    }

    private mouseEnter = (e: any) => {
        e.stopPropagation();
    };

    public getContent(): any {
        return this.state;
    }

    public onContentUpdate(newData: any): void {
        this.setState(newData);
    }

    private onClick = (event: React.SyntheticEvent) => {
        if (this.props.onClick !== undefined) {
            this.props.onClick(this, event);
        } else {
            this.sendEventToBackend("click", {
                guid: this.componentGuid,
                label: this.state.label,
                value: (this.state.value ? this.state.value : undefined),
            });
        }
    }
}

export default Tile;
