import OutgoingMessage from "./Message/OutgoingMessage";
import IncomingMessage from "./Message/IncomingMessage";

class WebsocketClient {
    private readonly onServerMessage: (message: IncomingMessage) => {};
    private readonly onConnectionError: (error: any) => {};

    private websocket!: WebSocket;
    private connected: boolean = false;

    constructor(
        onServerMessage: (message: IncomingMessage) => {},
        onConnectionError: (error: any) => {},
    ) {
        this.onServerMessage = onServerMessage;
        this.onConnectionError = onConnectionError;
    }

    public isConnected(): boolean {
        return this.connected;
    }

    public async start(
        url = "wss://" + window.location.hostname,
    ): Promise<boolean> {
        return await new Promise<boolean>((resolve: any, reject: any) => {
            this.websocket = new WebSocket(url);

            this.websocket.onmessage = (event: any) =>
                this.handleOnMessage(event);
            this.websocket.onclose = (event: any) => this.handleOnClose(event);

            this.websocket.onerror = (event: any) => {
                this.handleOnError(event);
                reject(false);
            };

            this.websocket.onopen = (event: any) => {
                this.handleOnOpen(event);
                resolve(true);
            };
        });
    }

    public sendDataToServer(message: OutgoingMessage) {
        try {
            if (this.websocket.readyState === this.websocket.OPEN) {
                this.websocket.send(JSON.stringify(message));
            }
        } catch (e) {
            this.connected = false;
            this.onConnectionError(e);
        }
    }

    private handleOnMessage(message: any): void {
        const data: string = message.data;

        try {
            const parsedMessage: IncomingMessage = JSON.parse(data);
            this.onServerMessage(parsedMessage);
        } catch (e) {
            console.log(e);
        }
    }

    private handleOnOpen(info: any) {
        this.connected = true;
    }

    private handleOnClose(info: any) {
        this.connected = false;
    }

    private handleOnError(info: any) {
        this.connected = false;
        this.onConnectionError(info);
    }
}

export default WebsocketClient;
