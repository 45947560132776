import * as React from "react";
import LoadingScreenWrapper from "./LoadingScreenWrapper";

class LoadingScreen extends React.Component<any, any> {
    public render() {
        return <LoadingScreenWrapper show={true}/>;
    }
}

export default LoadingScreen;
