import OutgoingMessage from "./OutgoingMessage";
import MessageCreator from "./MessageCreator";

export default class LoginSuccessMessageCreator implements MessageCreator<OutgoingMessage> {
    public static readonly type: string = "login_success";
    private message: OutgoingMessage;

    public constructor(outgoingMessage: OutgoingMessage) {
        outgoingMessage.type = LoginSuccessMessageCreator.type;
        this.message = outgoingMessage;
    }

    public getMessage(): OutgoingMessage {
        return this.message;
    }
}
