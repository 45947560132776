import * as React from "react";
import VayuModule from "../VayuModule";
import "./Heading.css";

class Heading extends VayuModule {
  public render() {
    let classStyling: string = "Heading-label";

  if (this.state.isItalic) {
    classStyling += " Text-italic";
  }
  if (this.state.isBold) {
    classStyling += " Text-bold";
  }

  if (this.state.isUnderline) {
    classStyling += " Text-underline";
  }

  if (this.state.isStrikeThrough) {
    classStyling += " Text-strikethrough";
  }

  if (this.state.isRedo) {
    classStyling += " Text-redo";
  }
  const styleText = {
    color:this.state.textColor
  };
  switch(this.state.type) {
    case "h1":
        return <h1 className={classStyling} style={styleText}>{this.state.value}</h1>;
        break;
    case "h2":
        return <h2 className={classStyling} style={styleText}>{this.state.value}</h2>;
        break;
    case "h3":
        return <h3 className={classStyling} style={styleText}>{this.state.value}</h3>;
        break;
    case "h4":
        return <h4 className={classStyling} style={styleText}>{this.state.value}</h4>;
        break;
    default:
        return <h2 className={classStyling} style={styleText}>{this.state.value}</h2>;
        break;
    return null;
  }
  

  }

  public onContentUpdate(newData: any): void {
    this.setState(newData);
  }

  protected getDefaultState(): object {
    return {
      value: "insert text here",
      label: "Text",
      isItalic: false,
      isBold: false,
      isUnderline: false,
      isStrikethrough: false,
      isRedo: false,
      textColor: "#000000",
      type:"h2",
    };
  }
 
  public getContent(): any {
    return this.state;
  }
}

export default Heading;
